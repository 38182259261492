
  .modalContainer {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 1000;
}

.modal {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    width: 40%;
    background-color: white;
    text-align: center;
    padding: 8vh 1vw;
    font-family: Poppins;
}

.modalMessage {
    font-size: 3vh;
    margin: 2vh 0;
    font-family: Poppins;
}

.modalIcon {
    font-size: 80px;
    color: green;
}




.resultContainer {
    width: 60%;
    margin: 3vh auto;
}

.result {
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    font-size: 3vh;
    font-family: Poppins;
    margin-bottom: 5vh;
}

.section {
    margin: 5vh 0;
}

.Title {
    font-size: 2.5vh;
    font-weight: bold;
    font-family: Poppins;
    text-decoration: underline;
}

.subTitle {
    font-size: 2.2vh;
    font-weight: bold;
    font-family: Poppins;
}

.content {
    font-size: 2.2vh;
    font-family: Poppins;
    margin-bottom: 0.5vh;
}