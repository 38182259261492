@import url("https://fonts.googleapis.com/css2?family=Jura:wght@500&family=Titillium+Web:wght@200;400;600&display=swap");
#mentorstext {
  text-align: center;
  font-family: "Titillium Web";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0em;
  color: #004aad;
  position: relative;
  cursor: pointer;
  margin-top: 5%;
}

#Bodycarouselimg1{
  display:none;
}


.mentor_maincard{
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.mentor_cards {
  width: 269px;
  height: 334px;
  border-radius: 25px;
  font-family: "Titillium Web";
  /* transition: transform .2s; */
  transition: width 1s;
  /* margin-right: 10vh; */
  padding-top: 1%;
  /* margin-left: 5% !important; */
  /* margin-right:6%; */
  cursor: pointer;
}
.mentor_img {
  text-align: center;
  border-radius: 15px;
  /* object-fit: scale-down; */
}

.mentor_img2 {
  height: 240px !important;
  margin: 1%;
  width:240px !important;
}

.mentor_body {
  font-family: "Titillium Web";
  margin-top: 5%;
}
.mentor_cards:hover {
  /* width:85%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 500px;
  padding: 1%;
}
.mentor-title > h5 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}
.mentor-designation {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  color: #ffffff;
}

.rec.rec-arrow {
  z-index: 15;
}

@media screen and (max-width: 1079px) {

  #Bodycarouselimg{
    display:none;
  }

  #Bodycarouselimg1{
    display:block;
  }

  .mentor_cards1 {
    width: 350px;
    height: 334px;
    border-radius: 25px;
    font-family: "Titillium Web";
    /* transition: transform .2s; */
    transition: height 1s;
    /* margin-right: 10vh; */
    padding: 5%;
    cursor: pointer;
    margin-left:9%;
  }
  .mentor_img1{
    width:100% !important;
    border-radius: 15px !important;
  }

  .mentor_cards1:hover {
    height:550px;
    display:block;
    width:269px !important;
  }

  .mentor_body1{
    font-family: "Titillium Web";
    margin-top: 5%;
  }

  #mentor_logo{
    display:none;
  }

  .mentor_img2{
    margin-right:0px;
    height:220px !important;
    padding:3%;
    border-radius: 15px !important;
  }

  .mentor-title1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
  }
  .mentor-designation1 {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
  
    color: #ffffff;
  }
}

@media screen and (min-width: 1080px) and (max-width:1240px) {
  .mentor_img2{
    width:150px !important;
  }
}