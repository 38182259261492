#testimonialstitle{
font-family: Jura;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 57px;
letter-spacing: 0em;
text-align: center;
color: #004AAD;


}
#bottomimage1{
    margin-top:-52%;
    z-index: -1;
    position: relative;
}
#bottomimage2>img{
    width:30%;
    height:100%;
    margin-top:-39.05%;
    
    
}
#bottomimage2{
    z-index: -1;
    position: relative;

}
@media screen and (max-width: 570px) {
    #testimages1{
     display: flex;
     flex-direction: column;
    }
    #testimages{
        display: flex;
        flex-direction: column;
       }
    #testimages2{
        display: flex;
     flex-direction: column;

    }
    #imagesoftest{
        margin-top:15%;
    }
    #bottomimage1{
        display: none;
        
    }
    #bottomimage2{
        display: none;
    }

    .slider_images{
        width:100% !important;
    }
}