.testGrid {
    width: 90%;
    margin: 4vh auto;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1vw;
    padding-bottom: 10vh;
}

.testItem {
    box-shadow: 0 0 15px 0 rgb(236, 236, 236);
    text-align: center;
    padding: 5vh 0;
}
.testIcon {
    width: 100px;
}
.testName {
    text-transform: capitalize;
    font-size: 2.5vh;
    margin: 5vh 0 2vh 0;
    font-family: Poppins;
    color: #2f3679;
    font-weight: bold;
    letter-spacing: 0.5px;
}
.testButton {
    background-color: #2f3679;
    color: white;
    padding: 0.5vh 1vw;
    width: fit-content;
    margin: auto;
    cursor: pointer;
    font-size: 2.5vh;
    border-radius: 5px;
    font-family: Poppins;
}

.greet {
    text-transform: capitalize;
    font-size: 2.5vh;
    font-weight: bold;
    font-family: Poppins;
    margin: 1vh 2vw;
    color: #2f3679;
}

.resultTitle {
    font-size: 2.5vh;
    text-decoration: underline;
    cursor: pointer;
    color: grey;
    width: fit-content;
    margin: auto;
}
.resultTitle:hover {
    color: #2f3679;
}