.application {
    width: 100%;
    height: 130vh;
    display: flex;
    position: absolute;
    top: 0;
}

.application-title {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.application-title-1 {
    font-size: 10vh;
    font-weight: bold;
    color: #1652BF;
}
.application-title-2 {
    font-size: 5vh;
    font-weight: bold;
    color: #1652BF;
}

.circle-container {
    width: 65%;
    overflow: hidden;
    position: relative;
}

.application-circle {
    background-color: rgb(79, 79, 233);
    width: 1000px;
    height: 1000px;
    border-radius: 100%;
    position: absolute;
    top: -40vh;
    right: -30vh;
}

.application-circle-heading {
    font-size: 10vh;
    font-weight: bold;
    color: white;
    position: absolute;
    width: 90%;
    top: 20vh;
    right: 0vw;
}

.download-now {
    font-size: 3vh;
    font-weight: bold;
    margin-top: 5vh;
    width: 45%;
    text-align: center;
}

.circle-phone {
    position: absolute;
    top: 35vh;
    right: 8vw;
    height: 450px;
}

@media screen and (max-width:500px) {
    .application-title-1 {
        font-size: 5vh;
    }
    .application-title-2 {
        font-size: 3vh;
    }
    .circle-phone {
        height: 250px;
        top: 33vh;
        right: 2%;
    }
    .application-circle {
        width: 600px;
        height: 600px;
        top: -10%;
        right: -150%;
    }
    .application {
        height: 90vh;
    }
    .application-title {
        height: 80vh;
    }
    .application-circle-heading {
        font-size: 3vh;
        top: 15vh;
        width: 90%;
        left: 17%;
    }
}







.test-container {
    width: 100%;
    margin-top: 130vh;
    margin-bottom: 5vh;
    position: relative;
}
.test-container-background {
    height: 1000px;
    z-index: -1;
}
.test-container-title {
    position: absolute;
    top: 40%;
    left: 10%;
    color: white;
    font-size: 10vh;
    font-weight: bold;
}
.phone1 {
    height: 450px;
    position: absolute;
    top: -30vh;
    left: 15%;
}
.phone2 {
    height: 450px;
    position: absolute;
    top: -20vh;
    left: 40%;
}
.phone3 {
    height: 450px;
    position: absolute;
    top: -5vh;
    left: 65%;
}
.phone4 {
    height: 450px;
    position: absolute;
    bottom: -5vh;
    left: 65%;
}
.phone5 {
    height: 450px;
    position: absolute;
    bottom: -20vh;
    left: 40%;
}
.phone6 {
    height: 450px;
    position: absolute;
    bottom: -30vh;
    left: 15%;
}

@media screen and (max-width: 500px) {
    .phone1 {
        height: 250px;
        left: 2%;
    }
    .phone2 {
        height: 250px;
        left: 35%;
    }
    .phone3 {
        height: 250px;
        left: 67%;
    }
    .phone4 {
        height: 250px;
        left: 67%;
    }
    .phone5 {
        height: 250px;
        left: 35%;
    }
    .phone6 {
        height: 250px;
        left: 2%;
    }

    .test-container-background {
        height: 500px;
        width: 100%;
    }

    .test-container-title {
        font-size: 4vh;
    }

    .test-container {
        margin-top: 90vh;
    }
}








.set-your-targets {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.set-your-targets-container1 {
    width: 60%;
    text-align: center;
    align-items: center;
    font-size: 10vh;
    font-weight: bold;
    color: #1652BF;
}

.set-your-targets-container2 {
    width: 40%;
    position: relative;
}

.set-your-targets-background {
    width: 100%;
}

.phone8 {
    height: 450px;
    position: absolute;
    left: 30%;
    top: 20%;
}

@media screen and (max-width: 500px) {

    .set-your-targets {
        margin-top: 30vh;
        width: 100%;
    }

    .set-your-targets-container1 {
        font-size: 4vh;
    }

    .set-your-targets-container2 {
        width: 80%;
    }

    .set-your-targets-background {
        width: 100%;
    }

    .phone8 {
        height: 250px;
        top: 3vh;
        left: 20vw;
    }
}






.build-your-resume {
    width: 75%;
    margin: 10vh auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.build-your-resume-title {
    font-size: 10vh;
    font-weight: bold;
    color: #1652BF;
    text-align: center;
}
.phone9 .phone10 {
    height: 450px;
}

@media screen and (max-width: 500px) {

    .build-your-resume {
        width: 100%;
    }

    .build-your-resume-title {
        font-size: 4vh;
    }
    
    .phone9{
        height: 250px;
    }
    .phone10 {
        height: 250px;
    }
}






.application-blogs {
    width: 100%;
    position: relative;
    margin-top: 5vh;
}
.application-blogs-main {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 15%;
}
.application-blogs-title {
    font-size: 10vh;
    font-weight: bold;
    color: white;
    width: 70%;
    text-align: center;
}
.application-blogs-subtitle {
    font-size: 5vh;
}
.application-blogs-image {
    height: 450px;
}
.application-bottom-background {
    position: absolute;
    z-index: -1;
    height: 500px;
}

@media screen and (max-width:500px) {
    
    .application-blogs-title {
        font-size: 4vh;
    }
    .application-blogs-subtitle {
        font-size: 2.5vh;
    }
    .application-blogs-image {
        height: 250px;
    }
    .application-bottom-background {
        height: 250px;
        width: 100%;
    }
    .application-blogs-main {
        left: 5%;
        margin: auto;
        top: -1vh;
        width: 90%;
    }
}
