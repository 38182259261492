#Fiveprocesstitle {
  color: #a0c9ff;
  font-family: Jura;
  font-size: 30px;
  margin-left: 5%;
  font-style: normal;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: left;
}
.Process_card {
  width: 90%;
  /* height: 70vh; */
  height:500px;
  /* margin-right: 5%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Processtitle {
  font-family: Jura;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.Processtext {
  font-family: Jura;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.Processimage {
  text-align: center;
}
.Processborder {
  text-align: center;
  margin: 0;
}
.process_1 {
  font-family: Jura;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  justify-content: left;
  /* text-align: left; */
  margin-left: 5%;
  color: #ff3636;
}
#processbottom {
  display: flex;
}

.process_2 {
  font-family: Jura;
  font-size: 24px;
  /* margin-top: -7%; */
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  letter-spacing: 0em;
  margin-left: auto;
  margin-right: auto;

  color: #004aad;
  margin-right: 5%;
}
#triangle{
    clip-path:polygon(0 0, 50% 50%, 0 100%);
    width:30px; 
    background-color: #2E11DB;
    height:28px;
    float: right;
    margin-right:3.3% ;
    margin-top: -4.5%;
    

}
@media screen and (max-width: 1250px) {
  #triangle{
    display:none;
  }
}