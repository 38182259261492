.formContainer {
    width: fit-content;
    margin: 4vh auto;
    /* box-shadow: 0 0 30px 0 rgb(236, 236, 236); */
    padding: 2vh 5vw;
    font-family: Poppins;
  }
  
  /* .form {
    width: 80%;
    margin: auto;
    margin-top: 10vh;
    padding: 3vh 0;
  } */
  
  .formItem {
    margin: 2vh 0;
    width: 400px;
    font-family: Poppins;
  }

  .label {
    font-family: Poppins;
    margin-bottom: 0;
  }
  
  .formInput {
    width: 100%;
    padding: 1vh 1vw;
    margin-top: 0.5vh;
    font-family: Poppins;
    border: 1px solid lightgrey;
    font-family: Poppins;
  }
  .formInput:focus {
    outline: #008037;
  }
  
  .formButton {
    color: white;
    background: #2f3679;
    padding: 1vh 0;
    cursor: pointer;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 1vh;
    border: none;
    font-family: Poppins;
    font-size: 2.5vh;
    border-radius: 5px;
  }
  /* .formButton:hover {
    background: #008037;
  } */
  
  /* @media screen and (max-width:500px) {
    .formContainer {
      width: 90%;
    }
  
    .form {
      margin-top: 5vh;
    }
  } */
  
  

  .modalContainer {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 1000;
}

.modal {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    width: 40%;
    background-color: white;
    text-align: center;
    padding: 8vh 1vw;
    font-family: Poppins;
}

.modalMessage {
    font-size: 3vh;
    margin: 2vh 0;
    font-family: Poppins;
}

.modalIcon {
    font-size: 80px;
    color: green;
}