* {
  /* display: none; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  
  
}
#instalogo{
  
  border:2px groove #dcdcdc;
  padding:3%;
  border-radius:50%;
}
a{
  color:unset;
}
#butt {
  width: 18%;
  /* height: 3rem; */
  font-size: 19px;
  /* background-color: rgb(26, 99, 214); */
  /* color: white; */
  font-family: 'Titillium Web', sans-serif;
  color: #2f3679; 
  font-weight: bolder;
  margin-top: -1%;
  margin-left: 5px;
}






#firstlogo {
  padding-left: 1.2rem;
  margin-top: -5%;
  width: 180px;
  height: 55px;
}

 
#i {
  color: #2f3679;
  /* color: white; */
  /* line-height: 2rem; */
  /* border: 2px solid red; */
  /* padding-right: 3rem; */
  font-family: 'Titillium Web', sans-serif;
  font-weight: bolder;
  font-size:20px;
  
}

.navbar-nav{

  /* border: 2px solid red; */
  width: 100%;
  margin-top: -2%;
  display: flex;
  
  margin-top: -1%;
  /* padding-right: 2rem; */
  justify-content: space-between;
}
#hide,
#socialHide,
#contactHide {
  display: none;
}

#popbody{
  
  margin-right:20%;
  margin-left: 20%;
  justify-content:center;
  background-color: white;
  border-radius:10px;
  margin-top:40px;
  margin-bottom:30px;
  /* color:white; */
}
#popbody>h3{
  margin-left: 5%;
}
#popbody .fa-times-circle{
  margin-left:70%;
}
#popbody .mb-1{
  margin-left: 5%;
  margin-right: 5%;
  
}
#popbody .btn-primary
{
  background-color:rgb(26, 99, 214)!important;
}
#popcss{
  border: 2px solid white;
  scroll-behavior: auto;
  scroll-margin-block: 10px;
  background-color: rgba(132, 125, 125, 0.17);
  backdrop-filter: blur(10px);
  background-size: 100%, 100vh;
}





/* text-decoration-line: no; */

@media screen and (max-width: 990px) {
  #butt {
    display: none;
  }
  #i {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: medium;
    font-weight: normal;
    border-bottom: 2px solid black;
  }
  #hide,
  #socialHide,
  #contactHide {
    display: block;
    /* border:2px solid white; */
  }
  #popbody{
    margin-right:20%;
    margin-left: 20%;
    justify-content:center;
    background-color: white;
    border-radius:10px;
    margin-top:40px;
    margin-bottom:30px;
    /* color:white; */
  }
}


#popcss {
  /* border: 2px solid white; */

  /* display: none; */
  /* text-align: center; */
  background-color: FFFFFF;
  /* min-width: 100vw; */
  min-height: 100vh;
  
  
  /* background-color: white; */
  background-color: rgba(132, 125, 125, 0.17);
  backdrop-filter: blur(10px);
  background-size: 100vw, 100vh;
  /* padding-top: 2rem; */
  /* height: 100%; */
  width: 100vw;
}


#popover {
  position: absolute;
  top: 0;
  z-index: 50;
  /* background-color: red; */
  /* border: 2px solid green; */

  height: 100vh;
  width: 100vw;
} 

@media screen and (max-width: 570px) {
  #popover{
    /* height:300vh !important; */
  }
  #popcss {
    /* border: 2px solid white; */
  
    /* display: none; */
    /* text-align: center; */
    background-color: FFFFFF;
    /* min-width: 100vw; */
    /* min-height: 200vh; */
    
    
    /* background-color: white; */
    background-color: rgba(132, 125, 125, 0.17);
    backdrop-filter: blur(10px);
    background-size: 100vw, 100vh;
    /* padding-top: 2rem; */
    /* height: 100%; */
    /* width: 100vw; */
  }
  #popbody{
    margin-right:7%;
    margin-left: 7%;
    justify-content:center;
    background-color: white;
    border-radius:10px;
    margin-top:40px;
    margin-bottom:30px;
    
  }
  #popbody .fa-times-circle{
    margin-left:40%;
  }
  
}








.register-button {
  border: none;
}