@import url("https://fonts.googleapis.com/css2?family=Space+Mono:ital@1&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@500&family=Titillium+Web:wght@200;400;600&display=swap');
.firstpart{
  display:flex;
  margin-right: 5%;
  margin-left: 5%;
}
#popup{
  display: none;
  position: absolute;
  background-color: white;
  padding-top:3%;
  padding-bottom: 2%;
  top: 12%;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  
}
#programcontent{
  display:flex;
 

  
}
#popprogram{
  float:right;
  margin-right: 4%;
}

.details{
  /* font-family: Georgia, serif; */
  font-family: 'Jura', sans-serif;
  /* margin-left: 2%; */
  padding: 10px;
  width:50%;
  text-align: justify;
  font-size: 15px;
}
.details>h4{
  /* font-family: 'Space Mono', monospace; */
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
  font-weight: bold;
}

a{
  text-decoration: none;
}
#class6{
  float:right;
   color: rgb(26, 99, 214);
   background-color:white;
   font-family: Georgia, serif;
}
#overflowtext2{
  float:right;
   color: rgb(26, 99, 214);
   background-color:white;
}
#overflowtext1{
  float:right;
   color: rgb(26, 99, 214);
   background-color:white;
}


@media screen and (max-width: 570px) {
  .firstpart{
    flex-direction: column;
  }
  .details{
    width: 100%;
  }
}