.blogonclick {
    display: flex;
    justify-content: space-evenly;
    width: 100%
}

.left {
    width: 10%;
}
.left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20vh;
}
.share {
    color: grey;
    font-size: 3vh;
}



.middle {
    width: 55%;
    padding-top: 5vh;
    padding-bottom: 5vh;
}
.middle-title {
    font-size: 4vh;
    font-weight: bold;
    margin-top: 3vh;
}
.middle-image {
    margin-top: 3vh;
    width: 100%;
    height: 60vh;
    object-fit: cover;
}



.right {
    width: 30%;
}
.right-container {
    margin: 0 3vh;
    margin-top: 40vh;
    text-align: center;
}
.morelikethis {
    font-size: 3vh;
    font-weight: bold;
    margin: 2vh 0;
}
.more-container {
    display: grid;
    gap: 4vh;
    grid-template-columns: repeat(1,1fr);
}
.more-blog {
    border: 1px solid gray;
    border-radius: 10px;
    padding: 1.5vh;
    display: flex;
}
.more-blog-container-1 {
    width: 65%;
    text-align: left;
}
.more-blog-1-title {
    font-weight: bold;
    font-size: 2.2vh;
}
.more-blog-1-content {
    font-size: 1.8vh;
}

.more-blog-container-2 {
    width: 35%;
    height: 15vh;
    object-fit: cover;
}

@media screen and (max-width:500px) {
    .right {
        display: none;
    }
    .left {
        display: none;
    }
    .middle {
        width: 90%;
    }
    .middle-title {
        font-size: 4vh;
    }
    .middle-image {
        height: 30vh;
    }
}