#our_programtitle {
  font-family: Jura;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 57px;

  letter-spacing: 0em;
  text-align: center;
  color: #004aad;
}

.ourprogramhead {
  margin-top: 2%;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 33px;
  letter-spacing: 0em;
  /* text-align: left; */
}
#our_programcard:hover{
  background: linear-gradient(180deg, #D8E2FB 0.01%, #F5F8FF 91.35%);


}
#our_programcard {
  width: 300px;
  height: 400px;
  background: #f8f8f8;
  border-radius: 34px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ourprogramtext {
  margin: 0px;
  font-family: Jura;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding:5%;
}

/* .our_programmtag > .rec.rec-arrow:disabled {
  visibility: hidden;
} */

.rec.rec-arrow:disabled {
  visibility: hidden;
}

/* button.rec-dot:disabled{
  visibility: hidden;
} */

/* .rec.rec-arrow:disabled{
  visibility: hidden;
} */

@media screen and (max-width: 990px) {
  .rec.rec-arrow:disabled {
    visibility: inherit;
  }
  #our_programcard {
    width: 250px;
    height: 400px;
    background: #f8f8f8;
    border-radius: 34px;
    cursor: pointer;
  }

  .ourprogramtext {
    margin: 1%;
    font-family: Jura;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
}
