.top-inner-container {
    background: white;
    height: 30vh;
    width: 30vw;
    position: absolute;
    top: 20%;
    left: 20%;
    padding: 5vh;
}

.top-picks-container {
    margin-top: 5vh;
    display: grid;
    gap: 10vh;
    grid-template-columns: repeat(3,1fr);
}

.top-picks-item {
    cursor: pointer;
}

.blog-container {
    border: 1px solid grey;
    border-radius: 10px;
    cursor: pointer;
    padding: 2vh;
    height: 30vh;
    display: flex;
    justify-content: space-between;
    background: white;
}

.blog-container1 {
    width: 50%;
}
.blog-container1-title {
    font-weight: bold;
    font-size: 3vh;
}
.blog-container1-subtitle {
    font-size: 1.8vh;
    overflow: hidden;
    word-wrap: wrap;
    margin: 1vh 0;
}
.blog-container1-tags {
    display: flex;
    font-size: 1.8vh;
}

.blogs-container {
    display: grid;
    gap: 5vh;
    grid-template-columns: repeat(2,1fr);
}