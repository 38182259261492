@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400&display=swap");

@media screen and (max-width: 700px) {
  .rec.rec-arrow:disabled {
    visibility: inherit;
  }
  .cloud_one {
    display: none;
  }
  .homemobilelogo{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }
  .cloud_left {

    display: inline;
    display:none;
    position: absolute;
    z-index: 1;
    top: 0px;
    
    right: 0px;
    /* width: 100vw; */
    /* height: 40vh; */
  }

  .write {
    /* margin-top:170px; */
    /* margin-right:30%; */
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .write > img {
    width: 100%;
    height: 100%;
  }
  .write1_img {
    display: none;
  }
  .write2_img {
    display: inline;
  }
  .aboutus_Top {
    /* margin-top: 400px; */
  }
  .aboutus_toggle {
    height: 80vh;
  }
  .aboutus_topFirst {
    width: 90%;
    margin: auto;
  }

  .aboutus_first {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 230px;
    /* z-index: 8; */
  }
  .aboutus_firstbox {
    width: 90%;
    height: 70vw;
    margin: auto;
  }

  .aboutus_vision {
    width: 90%;
    margin: auto;
    z-index: 9;
  }

  .aboutus_vision > .card_size {
    margin-right: 0;
    margin-top: 50px;
  }

  .aboutus_second {
    flex-direction:column;
    margin-top: 470px;
    width: 100%;
    height: 30vh;
  }
  .second_ourteam{
    width:90%;
  }
  
  .ourteam_card{
    margin-top:8%;
    width:100%;
  }
  .detail-text {
    font-size: 10px;
  }
  .team_card {
    width: 90%;
    height: 50vh;
  }

  .team_img {
    width: 100%;
    /* height: 800px; */
    padding: 10px;
  }

  .aboutus_third {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 450px;
  }

  .info_carousal,
  .rotated_card {
    width: 100%;
    height: 100%;
    left: 0;
  }

  .info_carousal > img{
    width:100%;
    height: auto;
  }

  .demo_info {
    margin-top: 450px;
    margin-right: 0px;
    width: 100%;
    position: relative;
  }
  .demo_info > h5{
    font-weight: 700;
  }
  .link_btn > button {
    width: 50vw !important;
    font-size: 14px;
  }

  .aboutus_fourth {
    margin-top: 780px;
  }

  .footer > div {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}

@media screen and (min-width: 701px) and (max-width: 990px) {

  .rec.rec-arrow:disabled {
    visibility: inherit;
  }
  .cloud_one {
    display: none;
  }
  .cloud_left {
    display: inline;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    /* width: 100vw; */
    /* height: 70vh; */
  }
  .lastCloud {
    margin-top: -70%;
  }

  .write {
    /* margin-top:200px; */
    /* margin-right:30%; */
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    align-items: center;
  }

  .write > img {
    width: 100%;
    height: 100%;
  }
  .write1_img {
    display: none;
  }
  .write2_img {
    display: block;
    width: 80% !important;
    height: 70vh !important;
    margin: auto;
  }
  .aboutus > h1 {
    margin-top: 100px;
  }

  .aboutus_Top {
    /* margin-top: 270px; */
    width: 100%;
  }
  .aboutus_toggle {
    height: 60vh;
  }
  .aboutus_topFirst {
    width: 90%;
    margin: auto;
  }
  .aboutus_first {
    margin-top: 230px;
    /* align-items:center; */
  }
  .aboutus_firstbox {
    width: 50%;
    height: 300px;
    /* margin-left:0; */
    margin-left: 20px;
  }

  .aboutus_vision {
    width: 40%;
    height: 300px;
    margin-right: 0;
  }

  .aboutus_second {
    margin-top: 200px;
    width: 100%;
    height: 30vw;
  }

  .second_ourteam{
    width:40%;
  }
  
  .ourteam_card{
    margin-top:10%;
    width:60%;
  }

  .team_card {
    width: 90%;
    height: 334px;
    transition: height 1s;
  }

  .team_card:hover {
    /* width:85%; */
    height: 430px;
    cursor: pointer;
  
    /* transform: scale(1.5); */
  }
  

  .team_img {
    width: 100%;
    height: 60%;
    padding: 10px;
  }

  .aboutus_third {
    margin-top: 250px;
  }

  .info_carousal,
  .rotated_card {
    width: 100%;
    height: 100%;
    left: 0;
  }

  .demo_info {
    margin-right: 20px;
    width: 50%;
    
  }
 .info_carousal>img{
   margin-top: -90px;
   width: 110%;
   height: auto;
 }

  .book_demobtn {
    width: 50vw;
  }

  .aboutus_fourth {
    margin-top: 350px;
  }

  .footer > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #newsletter1 {
    width: 200px !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .lastCloud {
    /* margin-top:-300%; */
    display: none;
  }
  .aboutus_third {
    margin-top: 150px;
  }
  .info_carousal>img{
    margin-top: -90px;
    width: 110%;
    height: auto;
  }
  .aboutus_fourth {
    margin-top: 350px;
  }
}
