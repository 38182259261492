.footertitle {
  font-family: "Titillium Web";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  color: #1b21b6;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
}
#footerend {
  width: 100%;
  /* height:45vh; */
  /* position:absolute; */
  /* background-color:#D8E2FB; */
  background: linear-gradient(180deg, #d8e2fb 0.01%, #f5f8ff 91.35%);
}
.footertext {
  font-family: "Titillium Web";
  font-size: 20px;
  font-style: normal;
  color: #1b21b6;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}
#subbtn {
  font-family: "Jura";
  font-size: 20px;
  color: #1b21b6;
  font-style: normal;
  font-weight: 300;
  z-index: 5;
  line-height: 24px;
  letter-spacing: 0em;
  /* background-color:#1B21B6; */
  background-color: #f5f8ff;
  text-align: left;
}
.subscribe{
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    margin-top: "100px";
}
#footericonsbody {
  background: #f5f8ff;
  width: 100%;
}
.facebook {
  width: 51px;
  z-index: 5;
  background: #216aee;
  height: 51px;
  text-align: center;
  border-radius: 50px;
}
.instagram {
  width: 51px;
  z-index: 5;
  background: #216aee;
  height: 51px;
  text-align: center;
  border-radius: 50px;
}
.linkedin {
  width: 51px;
  background: #216aee;
  height: 51px;
  z-index: 5;
  text-align: center;
  border-radius: 50px;
}
.iconscontainer {
  display: flex;
  flex-direction: row;
 
  justify-content: center;
  align-items: center;
}
.rtitles {
 
  font-family: "Jura";
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
 color: #939393;

/* text-align: left; */


  /* text-align: left; */
}
.redirecttitles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.copyrighttext {
  font-family: "Jura";
  font-size: 16px;
  font-style: normal;
  /* color:#FFFFFF; */
  color: white;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
#footerimages {
  margin-top: -50.5%;
  right: 0;
  left: 0;

  position: absolute;
}
.fleft {
  position: relative;
}
.fright {
  margin-top: -52%;
  position: relative;
}
/* #homepagemedia{
    background-color: #F5F8FF;
    margin-top: 33%;
    /* height: 3% !important;; */
/* width: 100%; */
/* } */
@media screen and (max-width: 570px) {
  .subscribe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}


/*footer css*/

.footer {
  overflow-x: hidden;
  height: 100vh;
  position: relative;
}

.grass {
  position: absolute;
  bottom: 0;
}

.girl {
  position: absolute;
  top: 130px;
  left: 110px;
  z-index: 1;
}

.flower3 {
  position: absolute;
  bottom: 110px;
  right: 10px;
}

.flower4 {
  position: absolute;
  right: 50px;
  bottom: 110px;
  z-index: 1;
}

.subscribe-container {
  color: white;
  position: absolute;
  left: 180px;
  bottom: 110px;
}

.subscribe-button {
  cursor: pointer;
}

.input {
  width: 320px;
  margin: 0 10px;
  padding-left: 0.5vw;
}
.input:focus {
  outline: none;
}

.error-message {
  align-items: center;
  justify-content: center;
  color: red;
  padding-top: 10px;
}

.footer-content {
  position: absolute;
  bottom: 20px;
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container1 {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
}
.container2 {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
}

.link {
  cursor: pointer;
}

.container3 {
  width: 25%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}


.parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon {
  position: absolute;
}


/*mobile css*/
@media only screen and (max-width:600px) {
  .footer {
    height: 50vh;
    position: relative;
  }

  .grass {
    position: absolute;
    height: 20vh;
    width: 100%;
    object-fit: cover;
  }

  .girl {
    width: 100px;
  }
}