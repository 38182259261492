@import url('https://fonts.googleapis.com/css2?family=Jura:wght@500&family=Titillium+Web:wght@200;400;600&display=swap');
* {
  /* display: none; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;


}
.homemobilelogo{
  display: none;
}


#homepage{
  display:flex;
  flex-direction: row;
  
   
}



#right{
  width: 50%;
  /* border:1px solid black; */
}
.logoright {
  position:absolute;
  top:-3%;
  right: 0;
  /* left: 0; */
  z-index: -1;
  bottom: 0;
  /* background-color:green; */
  
}

.logoleft{
  position:absolute; 
  /* left: 0; */
  top: 0;
  left: 0;
  /* right: 0; */
  bottom: 0;
  
  /* background-color:green; */
  
}

#homepagebody {
  width: 50%;
  margin-top: 1%;
  z-index: 1;
  
}
.text2 {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  /* font-family: 'Jura', sans-serif !important; */
  font-family: 'Titillium Web', sans-serif !important;
  font-size: 18px;
}
.text1 > h1 {
  /* width:70%; */
  font-weight: 700;
  font-size: 375%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    /* font-family: 'Titillium Web', sans-serif !important; */
}

#homepagetext {
  text-align: center;
  margin-top: 12%;
}
.inputbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
#buttonAll {
  background-color: rgb(26, 99, 214);
  color: white;
  font-weight: bold;
  padding: 6px 30px;
  text-align: center;

}
#countbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}
.countername {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
    font-family: 'Titillium Web', sans-serif !important;
  /* color:#007bff; */
  font-size: 16px;
}
.counternumber {
  animation: counter 4s ease-in;
  animation-fill-mode: forwards;
  counter-reset: num var(--num);
  font: 40px system-ui;
  font-family: 'Titillium Web', sans-serif !important;
}


#counterbox {
  text-align: center;
  width: 160px;
  height: 120px;
  font-size: large;
  border: 1px solid transparent;
  border-radius: 15px;
  margin-right: 10px;
}
#counterbox1 {
  text-align: center;
  width: 160px;
  height: 120px;
  font-size: large;
  border: 1px solid transparent;
  border-radius: 15px;
  margin-left: 20px;
}
.counternumber::after {
  content: counter(num);
}

.footer_body ,.main_footer {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* margin-top: 500px; */
  /* margin-top: 30%; */
  width:100%;
  align-items: center;
  background-color: transparent;
}

@keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}
@-webkit-keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}
@-moz-keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}
@-ms-keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}
@-o-keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}

.counternumber1 {
  animation: counter1 4s ease-in;
  animation-fill-mode: forwards;
  counter-reset: num var(--num);
  font:  40px system-ui;
}

.counternumber1::after {
  content: counter(num);
}
@keyframes counter1 {
  from {
    --num: 0;
  }
  to {
    --num: 10;
  }
}
@-webkit-keyframes counter1 {
  from {
    --num: 0;
  }
  to {
    --num: 10;
  }
}
@-moz-keyframes counter1 {
  from {
    --num: 0;
  }
  to {
    --num: 10;
  }
}
@-ms-keyframes counter1 {
  from {
    --num: 0;
  }
  to {
    --num: 10;
  }
}
@-o-keyframes counter1 {
  from {
    --num: 0;
  }
  to {
    --num: 10;
  }
}
.counternumber2 {
  animation: counter2 4s ease-in;
  animation-fill-mode: forwards;
  counter-reset: num var(--num);
  font: 40px system-ui;
  
}

.counternumber2::after {
  content: counter(num);
}
@keyframes counter2 {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}
@-webkit-keyframes counter2 {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}
@-moz-keyframes counter2 {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}
@-ms-keyframes counter2 {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}
@-o-keyframes counter2 {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}



#img{
  position: absolute;
  /* left: 0; */
  top: 4%;
  right: 0;
  /* bottom: 0; */
  width:100%;
  height: auto;
  margin-top: -1%;

}


#right{
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  
  /* background-color: red; */
}




/*Screen Size of 990px like tablets*/
@media screen and (max-width: 990px) {
  #homepagebody {
    width: 100%;
  }
  #homepage{
    display: block;

  }
  .logoleft{
   
  display:none;
  }
  
  #img{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left:-25%;
    max-width:130%;
  }
  
  #right{
    width:100%;
  } 
}

/*Resposive for screen size like laptops*/
@media screen and (max-width: 1250px) {
  #homepagebody {
    width: 100%;
  }
  #homepage{
    display: block;

  }
  .logoleft{
  
  display:none;
  }
  
  #img{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left:-25%;
    max-width:130%;
  }
  
  #right{
    width:100%;
  }
  
  
}


@media screen and (max-width: 570px) {
  #homepagebody {
    width: 100%;
    
  }

  #homepage{
    display: block;

  }
  .homemobilelogo{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }
  .logoleft{
    display: none;
    
  }
  .logoright{
    display: none;
  }
  
  #img{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left:-45%;
    width:130%;
    
    
  }
  
  #right{
    width:100%;
  }
  .text1{
    margin-top:10%;
  }
  .future{
    font-size:130%;
  }

  .footer_body > div {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  
  
}
/*responsive for mobiles*/
@media screen and (max-width: 360px) {
  #homepagebody {
    width: 100%;
  }
  #homepage{
    display: block;

  }
  .logoleft{
  display:none;
  }

  
  #img{
    position: relative;
    display: flex;
    flex-direction: column;
    /* margin-left:-25%; */
    /* max-width:130%; */
    margin-left:-45%;
    width:130%;
  }
  
  #right{
    width:100%;
  }
  .text2{
    font-size: 16px;
  }
  .text1{
    margin-top:10%;
  }

  .footer_body > div {
    margin-top:100px;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  #newsletter1 {
    width: 200px !important;
  }
}
@media screen and (max-width: 320px){
  .text1>h1{
    font-size: 350%;
      
  }
  .text2{
    font-size: 16px;
  }
  #buttonAll {
    background-color: rgb(26, 99, 214);
    color: white;
    font-weight: bold;
    padding: 6px 26px;
    text-align: center;
  
  }

  .footer_body > div {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  #newsletter1 {
    width: 200px !important;
  }
 
}