@import url("https://fonts.googleapis.com/css2?family=Jura:wght@500&family=Titillium+Web:wght@200;400;600&display=swap");
#showmobile {
  display: none !important;
}
#topcloud {
  position: absolute;
  top: 0;
  left: 0;
}
#text {
  /* font-family: Georgia, serif; */
  font-family: "Titillium Web", sans-serif !important;
  font-size: 30px;
  margin-top: -4%;
  color: rgb(26, 99, 214);
  font-weight: bolder;
  position: absolute;
  left: 45%;
  text-align: center;
}
#girl {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.detailsimg {
  position: relative;
  text-align: center;
}
#boy {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.programm_paydiv3 {
  text-align: center !important;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 15;
  align-items: center;
  justify-content: space-evenly;
  margin-top:-5.3%;
  margin-left: 35%;
  width: 43%;
}

.programm_pay3 {
  padding: 5px 30px;
  border: none;
  cursor: pointer !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: white !important;
  background-color: rgb(82, 170, 211) !important;
  border-radius: 10px !important;
  width: 13vw !important;
  font-family: "Titillium Web", sans-serif !important;
  /* margin-top: 1%; */
  z-index: 15;
  /* margin:20%; */
  /* justify-content:center;  */
}

@media screen and (max-width: 990px) {

  .payment_buttens3{
    display:none;
  }


  #classgradutes {
    display: none;
  }
  #showmobile {
    display: block !important;
  }
  #clouds {
    position: absolute;
    top: 0;
    right: 0;
    /* left: 0; */
    /* z-index: -1; */
    bottom: 0;
  }
  #textmob {
    /* font-family: Georgia, serif; */
    font-family: "Titillium Web", sans-serif !important;
    font-size: 30px;
    color: rgb(26, 99, 214);
    position: relative;
    margin-top: 5%;
    margin-left: 30%;
  }
  .title {
    text-align: center;
    margin-top: -21%;
    font-weight: bolder;
    font-size: x-large;
  }
  .details_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    /* margin-left: 0%; */
    height: 75vh;
    background-color: white;
    margin-top: 3%;
    /* z-index: 12; */
    /* background-color:none; */
  }
  .carousal_team {
    width: 50%;
  }

  .detailclass_card {
    width: 100%;
    height: 70vh;
    /* background-color: white; */
    /* height: 150%; */
    border-radius: 10px;
    background: white;
  }

  .head_img {
    margin: auto;
    width: 80%;
    height: 15%;
  }
  .card_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    /* height: 40%; */
  }
  .detail-text {
    color: black !important;
    /* font-family: Verdana, Arial, Helvetica, sans-serif; */
    font-family: "Jura", sans-serif !important;
    /* font-weight: 500; */
  }
  .down_img {
    width:90% !important;
    height:6vh;
    margin: auto !important;
    border: none;
    cursor: pointer !important;
    font-size:18px !important;
    font-weight: 600 !important;
    color:white !important;
    background-color:rgb(82, 170, 211)!important;
    border-radius:10px !important;
    font-family: 'Titillium Web', sans-serif !important;
  }
}
