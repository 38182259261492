@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400&display=swap");
.homemobilelogo{
  display: none;
}
.aboutus {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}

.cloud_left {
  display: none;
}

.cloud_one {
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
  width: 100%;
}

.upcloud2_img {
  display: none;
}

.write {
  position: absolute;
  z-index: 5;
  top: 50px;
  right: 0px;
  margin-top: 25px;
}

.write2_img {
  display: none;
}

.midcloud {
  position: absolute;
  z-index: 5;
}

.lastCloud {
  position: absolute;
  z-index: 6;
}

.aboutus > h1 {
  margin: auto;
  margin-top: 70px;
  font-weight: 700;
  color: #004aad;
  font-size: 45px;
  z-index: 10;
}

/*To box CSS*/
.aboutus_Top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 25vw;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}

.aboutus_topFirst {
  width: 40%;
  margin-left: 9%;
  background-color: whitesmoke;
  z-index: 10;
}

.card-text {
  font-size: 14px;
  z-index: 10;
  font-family: "Titillium Web";
}

.card-text > span {
  font-size: 14px;
  z-index: 10;
  font-family: "Titillium Web";
}

.read-more {
  padding: 2px;
  color: blue;
  background: none;
  outline: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  z-index: 10;
}

/* About Us First Card */
.aboutus_first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 25vw;
  margin-top: 50px;
  z-index: 10;
}

.aboutus_firstbox {
  margin-left: 6%;
  width: 45%;
  height: 20vw;
  z-index: 10;
}

.aboutus_firstbox > img {
  width: 100%;
  height: 100%;
  z-index: 10;
}

.aboutus_vision {
  margin-right: 9%;
  width: 35%;
  z-index: 10;
  font-family: "Titillium Web";
}

.aboutus_vision > .card_size {
  background-color: whitesmoke;
  font-size: 14px;
  margin-right: 9%;
  z-index: 10;
}

/* Team Member */
.aboutus_second {
  display:flex;
  flex-direction:row;
  align-items:center;
  margin-top: 100px;
  width: 100%;
  /* height: 430px; */
  margin-bottom: 0px;
  z-index: 10;
}

.second_ourteam{
  width:30%;
  /* position: static; */
  /* position: relative; */
}

.ourteam_card{
  width:70%;
  position:relative;
}

.team_card {
  width: 254px;
  height: 334px;
  border-radius: 25px;
  font-family: "Titillium Web";
  align-items: center;
  transition: height 1s;
  border-radius: 25px;
  padding: 7%;
  cursor: pointer;
}

.team_img {
  width: 100%;
  height: 60%;
  text-align: center;
  border-radius: 15px;
  margin: auto;
  z-index: 10;
}

.team_card:hover {
  height: 430px;
}

/* Connection Div */
.aboutus_third {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30vw;
  margin-top: 50px;
  z-index: 10;
}

.info_carousal,
.rotated_card {
  position: relative;
  width: 50%;
  height: 100%;
  left: 4%;
  z-index: 10;
  /* object-fit: contain; */
}

.info_carousal > img{
  margin-top:0px;
  width:90%;
  height:auto;
  margin-top: -50px;
  /* height:110vh; */
  /* object-fit: fill;; */
  /* object-fit: cover; */
}

.Card_float {
  height: 600px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 10px;
  z-index: 10;
}

.demo_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 80px;
  z-index: 10;
}

.demo_info > h5 {
  margin-top: 70px;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #263238;
  z-index: 10;
}

.book_demobtn {
  margin-bottom: 25px;
  width: 22vw;
  height: 48px;
  background: #1a63d6;
  border-radius: none;
  color: white;
  z-index: 10;
}

.SubscirbeBtn {
  background: #1a63d6;
  border-radius: none;
  color: white;
}

.store_images {
  display: flex;
  flex-direction: row;
}

/* Media images */
.aboutus_fourth {
  display:flex;
  flex-direction:column;
  margin-top: 400px;
  background: #d8e2fb;
  align-items: center;
  width: 100%;
  height: 100px;
  z-index: 10;
}

.aboutus_fourth > h5{
  margin-top:5px;
  align-items:center;
  font-size: 22px;
  font-family: "Titillium Web Bold";
  color:#000000;
}

.media_slider {
  /* margin-top: 5px; */
  align-items: center;
  z-index: 10;
}

.media_image {
  width: 100%;
  height: 50px;
  z-index: 10;
}

/* footer */
.footer {
  align-items: center;
  z-index: 10;
  background-color: white;
  bottom: 0;
}
.aboutus_fourth .iHAfmq{
  display: none;
}
/* div.iHAfmq{
  display: none;
} */
