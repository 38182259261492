.testContainer {
    width: 50%;
    margin: 10vh auto;
    padding: 5vh 3vw;
    background-color: rgba(246, 246, 246, 0.5);
}

.carousel {
    width: 100%;
}

.questionNo {
    text-transform: uppercase;
    /* color: rgb(205, 205, 205); */
    color: orange;
    font-family: Poppins;
    font-weight: bold;
    font-size: 1.8vh;
    text-align: left;
    margin-bottom: 1vh;
}

.question {
    font-family: Poppins;
    font-size: 2.8vh;
}

.nextButtonContainer {
    text-align: right;
}
.next {
    font-size: 30px;
    border: 0.5px solid #2f3679;
    cursor: pointer;
    color: #2f3679;
    margin-left: 0.5vw;
}

.loader {
    text-align: center;
    margin-top: 10vh;
}

.submitButton {
    background-color: #2f3679;
    color: white;
    font-family: Poppins;
    padding: 0.5vw 1vw;
    border: none;
    border-radius: 5px;
}

.modalContainer {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 1000;
}

.modal {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    width: 40%;
    background-color: white;
    text-align: center;
    padding: 8vh 1vw;
    font-family: Poppins;
}

.modalMessage {
    font-size: 3vh;
    margin: 2vh 0;
    font-family: Poppins;
}

.modalIcon {
    font-size: 80px;
    color: green;
}

.checkbox {
    cursor: pointer;
}
.option {
    font-family: Poppins;
    margin-left: 0.5vw;
}

