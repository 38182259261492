.payment {
  margin: 0px auto auto auto;
  display: flex;
  flex-direction: column;
  width: 99vw;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
#applycode {
  width: 15%;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: white !important;
  background-color: rgb(89, 153, 226) !important;
  height: 8%;
  /* margin-top:1.3%; */
}
#exampleInputcoupon {
  width: 37%;
}

.upcloud_pay {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 50%;
  height: 50%;
}

.pay_button:hover {
  cursor: pointer;
  background-color: #ff3f6c;
  color: #fff;
}

.detail_form {
  align-items: center;
}

.popbody {
  margin-right: 20%;
  margin-left: 20%;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  align-items: center;
  z-index: 12;
}
.popbody > h3 {
  margin-left: 40%;
  z-index: 12;
}
.popbody .fa-times-circle {
  margin-left: 70%;
  z-index: 12;
}
.popbody .mb-1 {
  margin-left: 5%;
  margin-right: 5%;
  z-index: 12;
}

.form_label {
  align-items: center;
  margin-left: 25%;
  z-index: 12;
}

.form_control {
  width: 50%;
  align-items: center;
  margin: auto;
  z-index: 12;
}

.btn-primary {
  /* width: 18vw !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  color: white !important;
  background-color: rgb(89, 153, 226) !important;
  z-index: 12 !important;
}

.btn-primary:hover {
  background-color: #ff3f6c !important;
}

@media only screen and (max-width: 570px) {
  .popbody {
    margin-left: 10%;
    margin-right: 10%;
  }
  .popbody > h3 {
    margin-left: 20%;
  }
  .form_label {
    align-items: center;
    margin-left: 10%;
  }

  .form_control {
    width: 100%;
    align-items: center;
    margin: auto;
  }

  .btn-primary {
    width: 30vw !important;
  }
  #couponblock {
    margin-left: 27.5%;
  }

  .exampleInputcoupon {
    width: 48vw !important;
    height: 8vh;
    margin-right: 10px;
  }
}
